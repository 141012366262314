/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'layout-text-sidebar-reverse': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M12.5 3a.5.5 0 010 1h-5a.5.5 0 010-1zm0 3a.5.5 0 010 1h-5a.5.5 0 010-1zm.5 3.5a.5.5 0 00-.5-.5h-5a.5.5 0 000 1h5a.5.5 0 00.5-.5m-.5 2.5a.5.5 0 010 1h-5a.5.5 0 010-1z"/><path pid="1" d="M16 2a2 2 0 00-2-2H2a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2zM4 1v14H2a1 1 0 01-1-1V2a1 1 0 011-1zm1 0h9a1 1 0 011 1v12a1 1 0 01-1 1H5z"/>',
    },
});
